
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from "react-router-dom";
import Slide from '@material-ui/core/Slide';


export default function Ueber(props) {
    const [slideIn1, setSlideIn1] = React.useState(false);
    const [slideIn2, setSlideIn2] = React.useState(false);

    useEffect(() => {
        setSlideIn1(true)
        setTimeout(() => {
            setSlideIn2(true)
        }, 100);
    }, [])

    return (

        <Grid
            style={{ height: '60vh' }}
            container
            direction="row"
            justifyContent="center"
            alignContent="baseline"
            spacing={3}
        >
            <Slide direction="right" in={slideIn1} {...(slideIn1 ? { timeout: 400 } : {})} mountOnEnter unmountOnExit>
                <Grid item xs={10}>
                    {/* <Typography align='left' style={{ fontSize: "calc(20px + 2vw)" }}>
                    CREATIVE AS F***K <span style={props.dark ? { color: '#3abb41', filter: "invert(100%)" } : { color: '#3abb41', filter: "invert(0%)" }}>#EKNG</span>
                </Typography> */}
                    <Typography align='left' style={{ fontSize: "calc(20px + 2vw)" }}>
                        <span style={props.dark ? { color: '#3abb41', filter: "invert(100%)" } : { color: '#3abb41', filter: "invert(0%)" }}>#EKNG</span>  KREATIVE LÖSUNGEN
                    </Typography>
                </Grid>
            </Slide>
            <Slide direction="left" in={slideIn1} {...(slideIn1 ? { timeout: 700 } : {})} mountOnEnter unmountOnExit>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignContent="center"
                    spacing={3}
                >
                    <Grid item xs={10}>
                        <Typography align='left' variant="h5">
                            Kundenspezifische Lösungen erfordern Erfindungsreichtum und eine überzeugende Idee.
                        </Typography >
                    </Grid >
                    <Grid item xs={10}>

                        <Typography align='left' variant="h5">
                            Die Ecknology GmbH verfügt über langjährige Erfahrung im Bereich der kreativen und individuellen Soft- und Hardware-Entwicklung, technischer Beratung und dem schöpferischen Austausch mit Kunden.
                            Von Elektronik über Prozessleittechnik und Microcontrollern bis Webentwicklung - für kundenspezifische Lösungen sind wir Ihr Ansprechpartner.<br></br>
                            Dabei sind Individualität, Zielstrebigkeit und Flexibilität unsere Stärke.
                        </Typography >
                        {/* <Typography align='justify'>
                    Die Ecknology GmbH verfügt über langjährige Erfahrung im Bereich der kreativen und individuellen Soft- und Hardware-Entwicklung, technischer Beratung und dem schöpferischen Austausch mit Kunden.
                    Von Elektronik über Prozessleittechnik und Microcontrollern bis Webentwicklung, sind wir Ihr Ansprechpartner<br></br>
                    Dabei sind wir professionell, legere oder auch mal Straße - Individualität, Besonnenheit und gute Laune sind unsere Stärke.
                </Typography > */}
                    </Grid >
                    <Grid item xs={10}>
                        <Typography align='left' variant="h5">
                            Lust auf einen kreativen Austausch? Nehmen Sie Kontakt auf oder besuchen Sie uns an der Elbe!
                        </Typography>
                    </Grid >

                </Grid >
            </Slide>
            <Slide direction="up" in={slideIn2} {...(slideIn2 ? { timeout: 1000 } : {})} mountOnEnter unmountOnExit>
                <Grid item xs={10}>

                    <Button
                        variant="outlined"
                        {...{
                            color: "inherit",
                            to: '/#/kontakt',
                            component: RouterLink,
                            onClick: () => {
                                props.setPage('/#/kontakt')
                            }
                        }}>
                        <Typography variant="h5" align='center'>
                            ran an die Buletten!
                        </Typography>
                    </Button>
                </Grid >
            </Slide>
        </Grid>

    );
}


