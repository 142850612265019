
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from "react-router-dom";

import Collapse from '@material-ui/core/Collapse';

export default function Impressum(props) {
    const [collapse, setCollapse] = React.useState(false);

    useEffect(() => {
        setCollapse(true)
    }, [])

    return (
        <Collapse in={collapse}>

            <Grid
                style={{ height: '60vh', }}
                container
                direction="row"
                justifyContent="center"
                alignContent="baseline"
                spacing={3}
            >
                <Grid item xs={10}>
                    <Typography align='left' style={{ fontSize: "calc(20px + 2vw)" }}>
                        IMPRESSUM
                    </Typography>
                </Grid>
                <Grid item xs={10}>
                    <Typography variant="subtitle2" align='justify'>
                        Verantwortlich für Inhalt & Gestaltung: <br></br>
                        Ecknology GmbH<br></br>
                        Behnstraße 6<br></br>
                        22767 Hamburg<br></br>
                        Telefon + 49 (0) 40 - 22 85 97 70 0<br></br>
                        Fax: + 49 (0) 40 - 22 85 97 70 9<br></br>
                        office@gecknology.de<br></br>
                        www.ecknology.de<br></br>
                        Geschäftsführer: René Eckardt / Nic Kröger<br></br>
                        Amtsgericht Hamburg HRB 127 506<br></br>
                        Ust-IdNr.: DE 815437540<br></br>
                        <br></br>
                        Alle anderen Firmen- und Produktbezeichnungen sowie deren Logos sind Marken der jeweiligen Inhaber oder anderweitig urheberrechtlich durch diese geschützt.
                        Sollten Sie defekte Links entdecken, senden Sie bitte eine e-mail an: office@ecknology.de
                        <br></br>
                        Disclaimer
                        Haftung für Inhalte
                        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. Haftung für Links
                        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen. Urheberrecht
                        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.                </Typography >
                </Grid>
            </Grid>
        </Collapse>

    );
}


