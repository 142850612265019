
import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from "react-router-dom";
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CodeIcon from '@material-ui/icons/Code';
import AppleIcon from '@material-ui/icons/Apple';
import ImageAspectRatioIcon from '@material-ui/icons/ImageAspectRatio';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BrightnessAutoIcon from '@material-ui/icons/BrightnessAuto';
import Slide from '@material-ui/core/Slide';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    headline: {
        fontSize: "calc(20px + 2vw)"
    },
    icon: {
        color: "rgb(8, 10, 32)"
    },
    iconDark: {
        color: "rgb(213, 214, 233)",
    }

}));
export default function Leistungen(props) {
    const classes = useStyles();

    const [open, setOpen] = React.useState('0');

    function handleClick(nr) {
        if (open != nr) {
            setOpen(nr);
        }
        else {
            setOpen(0)
        }
    };

    const [slideIn1, setSlideIn1] = React.useState(false);
    const [slideIn2, setSlideIn2] = React.useState(false);

    useEffect(() => {
        setSlideIn1(true)
        setTimeout(() => {
            setSlideIn2(true)
        }, 100);
    }, [])

    return (

        <Grid
            style={{ height: '60vh' }}
            container
            direction="row"
            justifyContent="center"
            alignContent="baseline"
            spacing={3}
        >
            <Slide direction="right" in={slideIn1} {...(slideIn1 ? { timeout: 400 } : {})} mountOnEnter unmountOnExit>
                <Grid item xs={10}>
                    <Typography align='left' className={classes.headline}>
                        WIR FÜR SIE
                    </Typography>
                </Grid>
            </Slide>
            <Slide direction="left" in={slideIn2} {...(slideIn2 ? { timeout: 400 } : {})} mountOnEnter unmountOnExit>
                <Grid item xs={10}>
                    <Typography variant="h4" align='left'>
                        Was machen die da überhaupt den ganzen Tag? Unsere Leistungen im Überblick
                    </Typography >
                </Grid>
            </Slide>
            <Slide direction="right" in={slideIn2} {...(slideIn2 ? { timeout: 700 } : {})} mountOnEnter unmountOnExit>
                <Grid item xs={10}>

                    <List>
                        <div onMouseEnter={() => handleClick(1)} onMouseLeave={() => setOpen(0)}>
                            <ListItem button onClick={() => handleClick(1)} >
                                <ListItemIcon>
                                    <CodeIcon className={props.dark ? classes.iconDark : classes.icon} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography style={{ fontSize: "calc(14px + 0.5vw)" }}>
                                        INDIVIDUELLE SOFTWARE-ENTWICKLUNG
                                    </Typography>
                                </ListItemText>

                                {open != 1 ? <ExpandMore /> : <ExpandLess />}
                            </ListItem>
                            <Collapse in={open != 1 ? false : true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <Typography>
                                            Benötigen Sie ein Verwaltungssoftware oder ein Exponat für eine Ausstellung.
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </div>

                        <div onMouseEnter={() => handleClick(2)} onMouseLeave={() => setOpen(0)}>
                            <ListItem button onClick={() => handleClick(2)} >
                                <ListItemIcon>
                                    <AppleIcon className={props.dark ? classes.iconDark : classes.icon} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography style={{ fontSize: "calc(14px + 0.5vw)" }}>
                                        WEBSEITEN UND APPS
                                    </Typography>
                                </ListItemText>

                                {open != 2 ? <ExpandMore /> : <ExpandLess />}
                            </ListItem>
                            <Collapse in={open != 2 ? false : true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <Typography>
                                            Ob HTML5 Landingpage, API oder React Dashboard, wir entwickeln das.
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </div>
                        <div onMouseEnter={() => handleClick(3)} onMouseLeave={() => setOpen(0)}>

                            <ListItem button onClick={() => handleClick(3)}>
                                <ListItemIcon>
                                    <ImageAspectRatioIcon className={props.dark ? classes.iconDark : classes.icon} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography style={{ fontSize: "calc(14px + 0.5vw)" }}>
                                        GRAFIKDESIGN
                                    </Typography>
                                </ListItemText>

                                {open != 3 ? <ExpandMore /> : <ExpandLess />}
                            </ListItem>
                            <Collapse in={open != 3 ? false : true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <Typography>
                                            Auch den Content liefern wir. Nutzen Sie unsere Fähigkeiten und lassen Sie Ihre Grafiken gleich mit erstellen.
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </div>
                        <div onMouseEnter={() => handleClick(4)} onMouseLeave={() => setOpen(0)}>

                            <ListItem button onClick={() => handleClick(4)} >
                                <ListItemIcon>
                                    <ApartmentIcon className={props.dark ? classes.iconDark : classes.icon} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography style={{ fontSize: "calc(14px + 0.5vw)" }}>
                                        ELEKTRONIK, ELEKTROTECHNIK UND INSTALLATION
                                    </Typography>
                                </ListItemText>

                                {open != 4 ? <ExpandMore /> : <ExpandLess />}
                            </ListItem>
                            <Collapse in={open != 4 ? false : true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <Typography>
                                            Ob Planung, Installation, Wartung oder Reparatur.
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </div>
                        <div onMouseEnter={() => handleClick(5)} onMouseLeave={() => setOpen(0)}>

                            <ListItem button onClick={() => handleClick(5)} >
                                <ListItemIcon>
                                    <BrightnessAutoIcon className={props.dark ? classes.iconDark : classes.icon} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography style={{ fontSize: "calc(14px + 0.5vw)" }}>
                                        AUTOMATISIERUNG UND PROZESSOPTIMIERUNG
                                    </Typography>
                                </ListItemText>

                                {open != 5 ? <ExpandMore /> : <ExpandLess />}
                            </ListItem>
                            <Collapse in={open != 5 ? false : true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <Typography>
                                            Full-Stack-Automatisierung und Regelungstechnik: SPS, ABB Freelance oder einfach nur IoT, wir regeln das!
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </div>
                        <div onMouseEnter={() => handleClick(6)} onMouseLeave={() => setOpen(0)}>

                            <ListItem button onClick={() => handleClick(6)} >
                                <ListItemIcon>
                                    <DesktopMacIcon className={props.dark ? classes.iconDark : classes.icon} />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography style={{ fontSize: "calc(14px + 0.5vw)" }}>
                                        DIGITALE MEDIEN
                                    </Typography>
                                </ListItemText>

                                {open != 6 ? <ExpandMore /> : <ExpandLess />}
                            </ListItem>
                            <Collapse in={open != 6 ? false : true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <Typography>
                                            IT Support und technische Betreuung. Wir begleiten Sie durch die schwierigsten Stunde vor dem Bildschirm und stehen Ihnen in jeder technischen oder Anwendungsfrage mit Lösungen zur Seite.                            </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </div>

                    </List>
                </Grid>
            </Slide>
            <Slide direction="up" in={slideIn2} {...(slideIn2 ? { timeout: 1000 } : {})} mountOnEnter unmountOnExit>
                <Grid item xs={10}>

                    <Button
                        variant="outlined"
                        {...{
                            color: "inherit",
                            to: '/#/kontakt',
                            component: RouterLink,
                            onClick: () => {
                                props.setPage('/#/kontakt')
                            }
                        }}>
                        <Typography variant="h5" align='center'>
                            Was darf es sein?
                        </Typography>
                    </Button>
                </Grid >
            </Slide>

        </Grid >

    );
}


