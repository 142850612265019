import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Slide from '@material-ui/core/Slide';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper.min.css";
import "swiper/components/effect-coverflow/effect-coverflow.min.css"
import "swiper/components/pagination/pagination.min.css"

// import Swiper core and required modules
import SwiperCore, {
    Autoplay,
    EffectCoverflow, Pagination
} from 'swiper/core';

import gsn from './GraphisoftNordGmbH.png';
import gsp from './logo_gsp_network.png';
import ece from './Ece-logo.svg';
import dfg from './DFG-logo-blau.svg';
import elk from './Elbkinder_Logo_CMYK_RZ_20120614.svg';
import uba from './ba.png';
import dah from './autohus_logo.png';
import hek from './Hanseatische_Krankenkasse_logo.svg';
import mdl from './Mondelez_international_2012_logo.svg';
import btg from './BTG-Logo-Button-PMS.svg';
import ldi from './logo.20180222182540.png';
import bmv from './Bundesministerium_für_Verkehr_und_digitale_Infrastruktur_Logo.svg';


// install Swiper modules
SwiperCore.use([Autoplay, EffectCoverflow, Pagination]);


const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        maxHeight: "100%",
        overflow: 'auto'
    },
    dialog: {
        maxWidth: "60%",
        minWidth: 400,
        maxHeight: 300,
        overflow: 'auto'
    },
    media: {
        height: 140,
    },
    dialogmedia: {
        height: "100%",
    },
    headline: {
        fontSize: "calc(20px + 2vw)"
    }
});
function MediaCard(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Card className={classes.root}>
                <CardActionArea onClick={() => handleClickOpen()}>
                    <CardMedia
                        className={classes.media}
                        image={props.image}
                        title={props.name}
                    />
                    <CardContent style={{
                        minHeight: 100,
                    }}>
                        <Typography gutterBottom fontSize={40} component="h2">
                            {props.name}
                        </Typography>
                    </CardContent>
                </CardActionArea>

            </Card>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" >
                    <img
                        style={{ width: "100%" }}
                        src={props.image}
                    />
                    {props.name}
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        {props.text}
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}


export default function Referenzen(props) {
    const classes = useStyles();

    const [slideIn1, setSlideIn1] = React.useState(false);
    const [slideIn2, setSlideIn2] = React.useState(false);

    useEffect(() => {
        setSlideIn1(true)
        setTimeout(() => {
            setSlideIn2(true)
        }, 100);
    }, [])

    return (
        <Grid
            style={{ height: '60vh' }}
            container
            direction="row"
            justifyContent="center"
            alignContent="baseline"
            spacing={3}
        >
            <Slide direction="right" in={slideIn1} {...(slideIn1 ? { timeout: 400 } : {})} mountOnEnter unmountOnExit>
                <Grid item xs={10}>

                    <Typography align='left' className={classes.headline}>
                        UNSERE PROJEKTE
                    </Typography>
                </Grid>
            </Slide>
            <Slide direction="left" in={slideIn2} {...(slideIn2 ? { timeout: 700 } : {})} mountOnEnter unmountOnExit>

                <Grid item xs={10} align='left' >

                    <Typography align='left' variant="h4">
                        Werfen Sie einen Blick hinter die Kulissen
                    </Typography>
                </Grid>
            </Slide >

            <Slide direction="right" in={slideIn2} {...(slideIn2 ? { timeout: 700 } : {})} mountOnEnter unmountOnExit>

                <Grid item xs={10} align='center' >
                    <Typography align='center' variant="h5">
                        Schrieben Sie uns eine <a style={props.dark ? { textDecoration: "none", color: '#3abb41', filter: "invert(100%)" } : { textDecoration: "none", color: '#3abb41', filter: "invert(0%)" }} href="mailto:office@ecknology.deg?subject=Kontaktaufnahme%20ecknology.de">E-MAIL</a> und erhalten Sie eine Übersicht unserer Projekte.
                    </Typography>
                </Grid>
            </Slide >

            <br /><br />
            <Slide direction="up" in={slideIn2} {...(slideIn2 ? { timeout: 1000 } : {})} mountOnEnter unmountOnExit>
                <Grid item xs={10} align='center' >

                    <form className={classes.root} noValidate autoComplete="off" style={{ padding: 20 }}>
                        <Typography align='left' >
                            Login
                        </Typography>
                        <TextField fullWidth style={props.dark ? { filter: "invert(100%)" } : { filter: "invert(0%)" }} type="email" id="email" label="E-Mail" variant="outlined" /> <br /><br />
                        <TextField fullWidth style={props.dark ? { filter: "invert(100%)" } : { filter: "invert(0%)" }} type="password" id="password" label="Passwort" variant="outlined" />
                        <br /><br />
                        <Button
                            variant="outlined"
                            style={props.dark ? { filter: "invert(100%)" } : { filter: "invert(0%)" }}
                            onClick={() => alert("E-Mail und Passwort passen nicht zusammen!")}
                        >
                            Login
                        </Button>
                    </form>
                </Grid>
            </Slide >

            {/* 
            <Grid item xs={10}>
                <Typography align='left' className={classes.headline}>
                    UNSERE PROJEKTE
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Typography align='center'>
                    RICHTIG FRISCH UND INNOVATIV
                </Typography >
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={3} style={{ backgroundColor: "#ffffff4d" }}>
                    <Swiper autoplay={{
                        "delay": 2500,
                        "disableOnInteraction": false
                    }} grabCursor={true} spaceBetween={30} slidesPerView={5} className="mySwiper" style={props.dark ? { padding: "30px", backgroundColor: "#ffffff4d" } : { padding: "30px" }} >
                        <SwiperSlide >
                            {MediaCard({
                                name: 'Selfie-Photo-Box',
                                image: 'https://selfiebox.ecknology.de/loginimg.jpg',
                                text: 'Die Selfie-Photo-Box wurde 2015 für die ECE Marketplace GmbH entwickelt und ist bis heute eine der Attraktionen in den ECE Einkaufscentern. Mit bis zu 8000 Bildern pro Monat pro Center, ein voller Erfolg!'
                            })}
                        </SwiperSlide>
                        <SwiperSlide >
                            {MediaCard({
                                name: 'Wanderausstellung „Unternehmen Berufsanerkennung“',
                                image: 'http://localhost:3000/uba.jpg',
                                text: 'Die Wanderausstellung „Unternehmen Berufsanerkennung“ informiert Unternehmen interaktiv über die Möglichkeiten der Berufsanerkennung. Acht Multimediastationen erzählen Erfolgsgeschichten, wie die berufliche Anerkennung zur Fachkräftesicherung eingesetzt werden kann. '
                            })}
                        </SwiperSlide>
                        <SwiperSlide >
                            {MediaCard({
                                name: 'Milka Lädele Erlebniswelt',
                                image: 'http://localhost:3000/laedele.png',
                                text: 'Du wolltest schon immer wissen, wie die zarteste Schokolade der Welt hergestellt wird? Seit über 100 Jahren wird in Bludenz deine geliebte Milka Alpenmilch Schokolade produziert und im Milka Lädele wird die Schokoladenproduktion spannend und anschaulich erklärt. Und das Beste: du kannst interaktiv teilhaben und auf Touchscreens die Produktion der zarten Milka Alpenmilch Schokolade steuern.'
                            })}
                        </SwiperSlide>
                        <SwiperSlide >
                            {MediaCard({
                                name: 'Interaktive Ausstellung - "Faszination Mensch"',
                                image: 'https://static1.abendzeitung-muenchen.de/storage/image/0/1/1/3/873110_artikelbild-flex-800w_1vclrY_MIa7Ax.jpg',
                                text: 'In sechs Ausstellungsbereichen und deren 50 interaktiven Stationen führt die Ausstellung „Faszination Mensch“ durch die faszinierende Welt des menschlichen Körpers und seiner Biologie. Anatomie spielt dabei ebenso eine Rolle wie der Stoffwechsel, die Sinne und die Gesundheit.'
                            })}
                        </SwiperSlide>
                        <SwiperSlide >
                            {MediaCard({
                                name: 'Wanderausstellung „VIELFALT ZÄHLT!“',
                                image: 'https://www.dfg.de/zentralablage/bilder/dfg_magazin/talks_vortraege_ausstellungen/ausstellungen/vielfaltzaehlt/bilderstrecke_besucherinformation/lightbox/02.jpg',
                                text: 'Eine Expedition durch die Biodiversität. Die DFG unterstützt diese Forschung in zahlreichen kleineren und größeren Forschungsprojekten. Mit ihrer Wanderausstellung „VIELFALT ZÄHLT! Eine Expedition durch die Biodiversität“ macht sie die Biodiversität und ihre Erforschung auch für die Öffentlichkeit erlebbar und verständlich. Multimedial und interaktiv vermittelt die Ausstellung, was Biodiversität bedeutet und warum sie für uns Menschen so wichtig und schützenswert ist.'
                            })}
                        </SwiperSlide>
                        <SwiperSlide >
                            {MediaCard({
                                name: 'Oberbillwerder, die Ausstellung zum Masterplan',
                                image: 'http://localhost:3000/iba.jpg',
                                text: 'Bereits seit 2019 informiert die wandernde Ausstellung Interessierte über Hamburgs 105. Stadtteil und lädt dazu ein, die Planungen für den neuen Stadtteil kennenzulernen. Sie gibt einen Rückblick auf den mehr als 2-jährigen Prozess bis zum Masterplan, stellt die wichtigsten Grundideen für den neuen Stadtteil vor und zeigt digital die Fachthemen des Masterplans, wie beispielsweise Wohnen, Mobilität, Entwässerung u. a. Rund 5.000 Besucherinnen und Besucher haben die Ausstellung in 2019 besucht.'
                            })}
                        </SwiperSlide>
                    </Swiper>
                </Paper>

            </Grid>
            <Grid item xs={10}>
                <Button
                    variant="outlined"
                    {...{
                        color: "inherit",
                        to: '/kontakt',
                        component: RouterLink,
                        onClick: () => {
                            props.setPage('/kontakt')
                        }
                    }}>
                    Kreative Lösung gefällig?
                </Button>
            </Grid>
            <Grid item xs={10}>
                <Typography align='left' className={classes.headline}>
                    UNSERE KUNDEN
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Typography align='center'>
                    WIR HABEN DIE FREUNDLICHSTEN UND HÜBSCHESTEN KUNDEN DER WELT, VIELEN DANK DAFÜR
                </Typography >
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={3} style={{ backgroundColor: "#ffffff4d" }}>
                    <Swiper autoplay={{
                        "delay": 2500,
                        "disableOnInteraction": false
                    }} grabCursor={true} spaceBetween={30} slidesPerView={5} className="mySwiper" style={props.dark ? { padding: "30px", backgroundColor: "#ffffff80" } : { padding: "30px" }} >
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + ece + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + mdl + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + bmv + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + ldi + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + uba + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + elk + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + gsn + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + dfg + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + dah + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + hek + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + btg + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                        <SwiperSlide ><div style={{ height: 100, backgroundImage: "url(" + gsp + ")", backgroundPosition: "center", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div> </SwiperSlide>
                    </Swiper>
                </Paper>

            </Grid>
            <Grid item xs={10}>
                <Button
                    variant="outlined"
                    {...{
                        color: "inherit",
                        to: '/kontakt',
                        component: RouterLink,
                        onClick: () => {
                            props.setPage('/kontakt')
                        }
                    }}>
                    WO IST MEIN LOGO?
                </Button>
            </Grid> */}
        </Grid >

    )
}