
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

const useStyles = makeStyles((theme) => ({
    headline: {
        fontSize: "calc(20px + 2vw)"
    }

}));

export default function Jobs(props) {
    const classes = useStyles();
    const [slideIn1, setSlideIn1] = React.useState(false);
    const [slideIn2, setSlideIn2] = React.useState(false);

    useEffect(() => {
        setSlideIn1(true)
        setTimeout(() => {
            setSlideIn2(true)
        }, 100);
    }, [])
    return (

        <Grid
            style={{ height: '60vh', }}
            container
            direction="row"
            justifyContent="center"
            alignContent="baseline"
            spacing={3}
        >
            <Slide direction="right" in={slideIn1} {...(slideIn1 ? { timeout: 400 } : {})} mountOnEnter unmountOnExit>
                <Grid item xs={10}>
                    <Typography align='left' className={classes.headline}>
                        SIE FÜR UNS?
                    </Typography>
                </Grid>
            </Slide>
            <Slide direction="left" in={slideIn1} {...(slideIn1 ? { timeout: 700 } : {})} mountOnEnter unmountOnExit>

                <Grid item xs={10}>
                    <Typography align='left' variant="h4">
                        Hier finden Sie unsere Stellenangebote
                    </Typography >
                </Grid >
            </Slide>
            <Slide direction="left" in={slideIn1} {...(slideIn1 ? { timeout: 700 } : {})} mountOnEnter unmountOnExit>

                <Grid item xs={10}>
                    <Typography variant="h5" align='center'>
                        Möchten Sie Teil unseres Teams werden? Senden Sie uns Ihre Daten zu.
                    </Typography >
                </Grid >
            </Slide >

            <Slide direction="up" in={slideIn2} {...(slideIn2 ? { timeout: 1000 } : {})} mountOnEnter unmountOnExit>

                <Grid item xs={10}>
                    <Button
                        variant="outlined"
                        {...{
                            color: "inherit",
                            to: '/#/kontakt',
                            component: RouterLink,
                            onClick: () => {
                                props.setPage('/#/kontakt')
                            }
                        }}>
                        <Typography variant="h5" align='center'>
                            NEHMEN SIE KONTAKT AUF
                        </Typography >

                    </Button>

                </Grid>
            </Slide >

        </Grid >

    );
}


