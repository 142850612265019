
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        maxHeight: 400,
        overflow: 'auto'
    },
    media: {
        height: 140,
    },
});
function MediaCard(props) {
    const classes = useStyles();
    return (
        <Card className={classes.root}>
            <CardActionArea onClick={() => alert('test')}>
                <CardMedia
                    className={classes.media}
                    image={props.image}
                    title={props.name}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {props.text}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default function Projekte(props) {
    return (
        <Grid
            style={{ height: '60vh', }}
            container
            direction="row"
            justifyContent="center"
            alignContent="baseline"
            spacing={3}
        >
            <Grid item xs={10}>
                <Typography align='left' style={{ fontSize: "calc(20px + 3vw)" }}>
                    RICHITG COOLE PROJEKTE
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <Typography align='center'>
                    WOW DAS IST JA DER HAMMER!
                </Typography >
            </Grid>
            <Grid item xs={3}>
                {MediaCard({
                    name: 'Selfie-Photo-Box',
                    image: 'https://selfiebox.ecknology.de/loginimg.jpg',
                    text: 'Die Selfie-Photo-Box wurde 2015 für die ECE Marketplace GmbH entwickelt und ist bis heute eine der Attraktionen in den ECE Einkaufscentern. Mit bis zu 8000 Bildern pro Monat pro Center, ein voller Erfolg!'
                })}
            </Grid>
            <Grid item xs={3}>
                {MediaCard({
                    name: 'Wanderausstellung „Unternehmen Berufsanerkennung“',
                    image: 'http://localhost:3000/uba.jpg',
                    text: 'Die Wanderausstellung „Unternehmen Berufsanerkennung“ informiert Unternehmen interaktiv über die Möglichkeiten der Berufsanerkennung. Acht Multimediastationen erzählen Erfolgsgeschichten, wie die berufliche Anerkennung zur Fachkräftesicherung eingesetzt werden kann. '
                })}            </Grid>
            <Grid item xs={3}>
                {MediaCard({
                    name: 'Milka Lädele Erlebniswelt',
                    image: 'http://localhost:3000/laedele.png',
                    text: 'Du wolltest schon immer wissen, wie die zarteste Schokolade der Welt hergestellt wird? Seit über 100 Jahren wird in Bludenz deine geliebte Milka Alpenmilch Schokolade produziert und im Milka Lädele wird die Schokoladenproduktion spannend und anschaulich erklärt. Und das Beste: du kannst interaktiv teilhaben und auf Touchscreens die Produktion der zarten Milka Alpenmilch Schokolade steuern.'
                })}            </Grid>
            <Grid item xs={3}>
                {MediaCard({
                    name: 'Interaktive Ausstellung - "Faszination Mensch"',
                    image: 'https://static1.abendzeitung-muenchen.de/storage/image/0/1/1/3/873110_artikelbild-flex-800w_1vclrY_MIa7Ax.jpg',
                    text: 'In sechs Ausstellungsbereichen und deren 50 interaktiven Stationen führt die Ausstellung „Faszination Mensch“ durch die faszinierende Welt des menschlichen Körpers und seiner Biologie. Anatomie spielt dabei ebenso eine Rolle wie der Stoffwechsel, die Sinne und die Gesundheit.'
                })}            </Grid>
            <Grid item xs={3}>
                {MediaCard({
                    name: 'Wanderausstellung „VIELFALT ZÄHLT! Eine Expedition durch die Biodiversität“',
                    image: 'https://www.dfg.de/zentralablage/bilder/dfg_magazin/talks_vortraege_ausstellungen/ausstellungen/vielfaltzaehlt/bilderstrecke_besucherinformation/lightbox/02.jpg',
                    text: 'Die DFG unterstützt diese Forschung in zahlreichen kleineren und größeren Forschungsprojekten. Mit ihrer Wanderausstellung „VIELFALT ZÄHLT! Eine Expedition durch die Biodiversität“ macht sie die Biodiversität und ihre Erforschung auch für die Öffentlichkeit erlebbar und verständlich. Multimedial und interaktiv vermittelt die Ausstellung, was Biodiversität bedeutet und warum sie für uns Menschen so wichtig und schützenswert ist.'
                })}            </Grid>
            <Grid item xs={3}>
                {MediaCard({
                    name: 'Oberbillwerder, die Ausstellung zum Masterplan',
                    image: 'http://localhost:3000/iba.jpg',
                    text: 'Bereits seit 2019 informiert die wandernde Ausstellung Interessierte über Hamburgs 105. Stadtteil und lädt dazu ein, die Planungen für den neuen Stadtteil kennenzulernen. Sie gibt einen Rückblick auf den mehr als 2-jährigen Prozess bis zum Masterplan, stellt die wichtigsten Grundideen für den neuen Stadtteil vor und zeigt digital die Fachthemen des Masterplans, wie beispielsweise Wohnen, Mobilität, Entwässerung u. a. Rund 5.000 Besucherinnen und Besucher haben die Ausstellung in 2019 besucht.'
                })}            </Grid>
            <Grid item xs={3}>
                {MediaCard({
                    name: 'Geht doch! Inklusion erfahren.',
                    image: 'http://localhost:3000/gehtdoch.jpg',
                    text: 'Sonderausstellung im Hamburg Museum zum Thema Inklusion. Als Anlass zum 150 jährige Bestehen der Evangelischen Stiftung Alsterdorf, wurde 2013 diese interaktive Erlebnisausstellung zum Großteil durch #EKNG programmiert und umgesetzt.'
                })}            </Grid>
            <Grid item xs={10}>
                <Button
                    variant="outlined"
                    {...{
                        color: "inherit",
                        to: '/kontakt',
                        component: RouterLink,
                        onClick: () => {
                            props.setPage('/kontakt')
                        }
                    }}>
                    Nehmen Sie Kontakt auf
                </Button>
            </Grid>
        </Grid>

    );
}


