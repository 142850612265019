import {
    AppBar,
    Toolbar,
    Typography,
    makeStyles,
    Button,
    IconButton,
    Drawer,
    Link,
    MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness4OutlinedIcon from '@material-ui/icons/Brightness4Outlined';
import React from "react";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import ecknology_gmbh_logo from './ecknology-logo.svg';

const headersData = [
    {
        label: "ÜBER ECKNOLOGY",
        href: "/#/ueber",
    },
    {
        label: "LEISTUNGEN",
        href: "/#/leistungen",
    },
    {
        label: "JOBS",
        href: "/#/jobs",
    },
    {
        label: "REFERENZEN",
        href: "/#/referenzen",
    },
];

const useStyles = makeStyles(() => ({
    // header: {
    //     "@media (max-width: 900px)": {
    //         paddingLeft: 0,
    //     },
    // },
    logo: {
        //fontFamily: "Work Sans, sans-serif",
        color: "#3abb41",
    },
    menuButton: {
        //fontFamily: "Open Sans, sans-serif",
        size: "18px",
        marginLeft: "38px",
        fontWeight: 300,
        color: "#000000",
        '&:hover': {
            fontWeight: 700,
        },
        '&:active': {
            fontWeight: 700,
            color: "#3abb41"
        },
    },
    menuButtonActive: {
        size: "18px",
        marginLeft: "38px",
        fontWeight: 700,
        color: "#3abb41"
    },
    menuButtonContact: {
        position: 'absolute',
        right: 0,
        top: 150,
        transform: 'rotate(90deg)',
        size: "18px",
        marginLeft: "38px",
    },
    toolbar: {
        marginTop: 20,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    drawerContainer: {
        padding: "20px 30px",
    },
    logoImage: {
        height: '100px',
        alignSelf: 'flex-end',
        paddingLeft: 20
    }
}));

export default function Header(props) {
    const { menuButtonActive, logo, menuButton, toolbar, drawerContainer, logoImage, menuButtonContact } = useStyles();

    const [scroll, setScroll] = useState(false);

    const changeNavbar = () => {
        if (window.scrollY >= 50) {
            setScroll(true)
        } else setScroll(false)
    }

    window.addEventListener("scroll", changeNavbar)


    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    console.log(props.page);

    const { mobileView, drawerOpen } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    const displayDesktop = () => {
        return (
            <div >
                <AppBar color="transparent" style={{ boxShadow: "none" }} position="fixed" >
                    <Toolbar className={toolbar} style={props.dark ? { filter: "invert(100%)" } : {}} >
                        {ecknology_gmbh}
                        <div style={{
                            marginLeft: 'auto'
                        }} >
                            {getMenuButtons()}
                            <IconButton
                                style={scroll ? { backgroundColor: 'rgb(213, 214, 233)' } : {}}

                                className={menuButton}
                                onClick={() => {
                                    if (!props.dark) {
                                        props.setDark(true)
                                    }
                                    else props.setDark(false)
                                }}
                            >
                                {props.dark ? <Brightness4Icon /> : <Brightness4OutlinedIcon />}
                            </IconButton>
                        </div>
                        {/* <div>{getMenuButtons()}
                    <Button to='/'>
                        
                    </Button>
                </div> */}
                        <img {...{
                            color: "inherit",
                            to: '/',
                            component: RouterLink,
                            onClick: () => {
                                props.setPage('')
                            }
                        }} alt='logoImage' className={logoImage} src={ecknology_gmbh_logo}></img>
                        <Button
                            className={menuButtonContact}
                            style={scroll ? { backgroundColor: 'rgb(213, 214, 233)' } : {}}
                            {...{
                                color: "inherit",
                                to: '/#/kontakt',
                                component: RouterLink,
                                onClick: () => {
                                    props.setPage('/#/kontakt')
                                }
                            }}
                        >
                            <Typography variant='subtitle1' style={{ color: "#3abb41" }} >
                                KONTAKT
                            </Typography>
                        </Button>
                    </Toolbar>
                </AppBar>

                {scroll ? null : <Typography variant="body2" color="textSecondary" align="right" style={{
                    textAlign: "left",
                    position: 'absolute',
                    right: -110,
                    bottom: 200,
                    transform: 'rotate(90deg)',
                    size: "18px",
                    filter: props.dark ? "invert(100%)" : "invert(0%)"
                }}>
                    {'Copyright © '}
                    <Link color="inherit" href="https://ecknology.de/">
                        Ecknology GmbH
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {' / '}
                    <Link color="inherit" {...{
                        color: "inherit",
                        to: '/#/impressum',
                        component: RouterLink,
                        onClick: () => {
                            props.setPage('/#/impressum')
                        }
                    }}>
                        Impressum
                    </Link>
                </Typography>}
            </div>
        );
    };

    const displayMobile = () => {
        const handleDrawerOpen = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: true }));
        const handleDrawerClose = () =>
            setState((prevState) => ({ ...prevState, drawerOpen: false }));

        return (
            <Toolbar>
                <IconButton
                    {...{
                        edge: "start",
                        color: "inherit",
                        "aria-label": "menu",
                        "aria-haspopup": "true",
                        onClick: handleDrawerOpen,
                    }}
                >
                    <MenuIcon />
                </IconButton>

                <Drawer
                    {...{
                        anchor: "left",
                        open: drawerOpen,
                        onClose: handleDrawerClose,
                    }}
                >
                    <div className={drawerContainer}>
                        {getDrawerChoices()}
                        <Link
                            {...{
                                component: RouterLink,
                                to: '/#/kontakt',
                                color: "inherit",
                                style: { textDecoration: "none" },
                                key: 'kontakt',
                                onClick: () => {
                                    props.setPage('/#/kontakt')
                                    setState((prevState) => ({ ...prevState, drawerOpen: false }));
                                }
                            }}
                        >
                            <MenuItem>{'KONTAKT'}</MenuItem>
                        </Link>
                        <Link color="inherit" href="https://ecknology.de/" {...{
                            color: "inherit",
                            to: '/#/impressum',
                            component: RouterLink,
                            onClick: () => {
                                props.setPage('/#/impressum')
                                setState((prevState) => ({ ...prevState, drawerOpen: false }));
                            }
                        }}>
                            <MenuItem>{'IMPRESSUM'}</MenuItem>
                        </Link>
                        <IconButton
                            style={scroll ? { backgroundColor: 'rgb(213, 214, 233)' } : {}}

                            className={menuButton}
                            onClick={() => {
                                if (!props.dark) {
                                    props.setDark(true)
                                }
                                else props.setDark(false)
                            }}
                        >
                            {props.dark ? <Brightness4Icon /> : <Brightness4OutlinedIcon />}
                        </IconButton>
                        <Typography color="textSecondary" align="right" style={{
                            position: "absolute",
                            fontSize: "1.5vw",
                            bottom: 0,
                        }}>
                            {'Copyright © '}
                            <Link color="inherit" href="https://ecknology.de/">
                                Ecknology GmbH
                            </Link>{' '}
                            {new Date().getFullYear()}

                        </Typography>
                    </div>
                </Drawer>

                <div>{ecknology_gmbh}</div>
            </Toolbar>
        );
    };

    const getDrawerChoices = () => {
        return headersData.map(({ label, href }) => {
            return (
                <Link
                    {...{
                        component: RouterLink,
                        to: href,
                        color: "inherit",
                        style: { textDecoration: "none" },
                        key: label,
                        onClick: () => {
                            props.setPage(href)
                            setState((prevState) => ({ ...prevState, drawerOpen: false }));
                        }
                    }}
                >
                    <MenuItem>{label}</MenuItem>
                </Link>
            );
        });
    };

    const ecknology_gmbh = (
        <Button
            {...{
                color: "inherit",
                to: '/',
                component: RouterLink,
                onClick: () => {
                    props.setPage('')
                }
            }}
        >
            <Typography variant='subtitle1' className={logo}>
                #EKNG
            </Typography>
        </Button>

    );

    const getMenuButtons = () => {
        return headersData.map(({ label, href }) => {
            return (
                <Button
                    style={scroll ? { backgroundColor: 'rgb(213, 214, 233)' } : {}}
                    {...{
                        key: label,
                        color: "inherit",
                        to: href,
                        component: RouterLink,
                        className: props.page === href ? menuButtonActive : menuButton,
                        onClick: () => {
                            props.setPage(href)
                        }
                    }}
                >
                    <Typography variant='subtitle1' >
                        {label}
                    </Typography>
                </Button>
            );
        });
    };

    return (
        <header key={props.dark} >
            {mobileView ? displayMobile() : displayDesktop()}
        </header>
    );
}