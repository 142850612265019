
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

import BusinessIcon from '@material-ui/icons/Business';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import DirectionsIcon from '@material-ui/icons/Directions';
import map from './map.png';

const useStyles = makeStyles((theme) => ({
    headline: {
        fontSize: "calc(20px + 2vw)"
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    }

}));

export default function Kontakt(props) {
    const classes = useStyles();
    const [slideIn1, setSlideIn1] = React.useState(false);
    const [slideIn2, setSlideIn2] = React.useState(false);
    const [slideIn3, setSlideIn3] = React.useState(false);

    useEffect(() => {
        setSlideIn1(true)
        setTimeout(() => {
            setSlideIn2(true)
            setTimeout(() => {
                setSlideIn3(true)
            }, 100);
        }, 100);
    }, [])

    return (

        <Grid
            container
            direction="row"
            justifyContent="center"
            alignContent="center"
            spacing={3}
        >
            <Slide direction="left" in={slideIn1} {...(slideIn1 ? { timeout: 400 } : {})} mountOnEnter unmountOnExit>

                <Grid item xs={8}>
                    <Typography align='left' className={classes.headline}>
                        <span style={props.dark ? { color: '#3abb41', filter: "invert(100%)" } : { color: '#3abb41', filter: "invert(0%)" }}>#EKNG</span> IM HERZEN ALTONAS
                    </Typography>
                </Grid>
            </Slide>
            <Slide direction="right" in={slideIn1} {...(slideIn1 ? { timeout: 700 } : {})} mountOnEnter unmountOnExit>

                <Grid item xs={8}>
                    <Typography align='center' variant="h3" >
                        Kontaktieren Sie uns!
                    </Typography>
                </Grid>
            </Slide>
            <Slide direction="up" in={slideIn2} {...(slideIn2 ? { timeout: 1000 } : {})} mountOnEnter unmountOnExit>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignContent="center"
                    spacing={3}
                >

                    <Grid item xs={10} md={5} justifyContent="flex-start" >
                        <Paper elevation={3} style={{ padding: 30, backgroundColor: "#ffffff", opacity: 0.7 }} >
                            <Typography variant="h5" align='left' className={classes.icon} >
                                <BusinessIcon fontSize="large" style={{ paddingRight: 15 }} />  ECKNOLOGY GMBH
                            </Typography>
                            <br></br>
                            <Typography variant="h5" align='left' className={classes.icon}>
                                <DirectionsIcon fontSize="large" style={{ paddingRight: 15 }} /> BEHNSTRASSE 6
                            </Typography>
                            <br></br>
                            <Typography variant="h5" align='left' className={classes.icon}>
                                <LocationCityIcon fontSize="large" style={{ paddingRight: 15 }} />22767 HAMBURG
                            </Typography>
                            <br></br>
                            <Typography variant="h5" align='left' className={classes.icon}>
                                <PhoneIcon fontSize="large" style={{ paddingRight: 15 }} />  <a style={props.dark ? { textDecoration: "none", color: '#3abb41', filter: "invert(100%)" } : { textDecoration: "none", color: '#3abb41', filter: "invert(0%)" }} href="callto:+ 49 (0) 40 - 22 85 97 70 0">+ 49 (0) 40 - 22 85 97 70 0</a>
                            </Typography>
                            <br></br>
                            <Typography variant="h5" align='left' className={classes.icon}>
                                <EmailIcon fontSize="large" style={{ paddingRight: 15 }} />  <a style={props.dark ? { textDecoration: "none", color: '#3abb41', filter: "invert(100%)" } : { textDecoration: "none", color: '#3abb41', filter: "invert(0%)" }} href="mailto:office@ecknology.deg?subject=Kontaktaufnahme%20ecknology.de">OFFICE@ECKNOLOGY.DE</a>
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Slide >

        </Grid >

    );
}


