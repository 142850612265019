import './App.css';
import { useState, useEffect } from "react";

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';

import Particles from "react-tsparticles";
import { BrowserRouter as Router, useLocation, Switch, Route } from "react-router-dom";
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

import Start from './Start.js'
import Header from './Header.js'
import Ueber from './Ueber.js'
import Leistungen from './Leistungen.js'
import Projekte from './Projekte.js'
import Referenzen from './Referenzen.js'
import Jobs from './Jobs.js'
import Kontakt from './Kontakt.js'
import Impressum from './Impressum.js'

import map from './map.png';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "100px",
    height: '80vh',
    overflow: 'hidden'
  },
}));

function App() {
  const classes = useStyles();

  const [dark, setDark] = useState(false);

  const [page, setPage] = useState('');
  const [transition, setTransition] = useState(false);
  const [tsConfImage, setTsConfImage] = useState('');
  const [tsConfColor, setTsConfColor] = useState({});

  useEffect(() => {
    if (page == "/#/kontakt") {
      setTsConfImage("url('" + window.location.protocol + "//" + window.location.host + "/map.png')")
      // setTsConfColor({ "value": "rgba(1,1,1)" })
    }
    else {
      setTsConfImage("")
      // setTsConfColor({ "value": dark ? "#080a20" : "#d5d6e9" })
    }

  }, [page, dark])

  useEffect(() => {

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // dark mode
      setDark(true)
    }
  }, [])

  const tsConf = {
    "autoPlay": true,
    "background": {
      "color":
        { "value": dark ? "#080a20" : "#ffdfe9" },
      "position": "50% 50%",
      "repeat": "no-repeat",
      "size": "cover",
      "opacity": 1
    },
    "backgroundMask": {
      "composite": "destination-out",
      "cover": {
        "color": {
          "value": "#fff"
        },
        "opacity": 1
      },
      "enable": false
    },
    "fullScreen": {
      "enable": true,
      "zIndex": -1
    },
    "detectRetina": true,
    "duration": 0,
    "fpsLimit": 25,
    "interactivity": {
      "detectsOn": "window",
      "events": {
        "onClick": {
          "enable": true,
          "mode": "attract"
        },
        "onDiv": {
          "selectors": [],
          "enable": false,
          "mode": [],
          "type": "circle"
        },
        "onHover": {
          "enable": true,
          "mode": "bounce",
          "parallax": {
            "enable": false,
            "force": 60,
            "smooth": 10
          }
        },
        "resize": true
      },
      "modes": {
        "attract": {
          "distance": 1000,
          "duration": 1,
          "easing": "ease-out-quad",
          "factor": 1,
          "maxSpeed": 10,
          "speed": 2
        },
        "bounce": {
          "distance": 100,
          "maxSpeed": 1.5,
          "speed": 1.5,
          "factor": 1
        },
        "bubble": {
          "distance": 400,
          "duration": 2,
          "mix": false,
          "opacity": 0.8,
          "size": 40
        },
        "connect": {
          "distance": 80,
          "links": {
            "opacity": 0.1
          },
          "radius": 60
        },
        "grab": {
          "distance": 400,
          "links": {
            "blink": false,
            "consent": false,
            "opacity": 0.1
          }
        },
        "push": {
          "default": true,
          "groups": [],
          "quantity": 4
        },
        "remove": {
          "quantity": 2
        },
        "repulse": {
          "distance": 200,
          "duration": 0.4,
          "factor": 100,
          "speed": 1,
          "maxSpeed": 50,
          "easing": "ease-out-quad"
        },
        "slow": {
          "factor": 3,
          "radius": 200
        },
        "trail": {
          "delay": 1,
          "pauseOnStop": false,
          "quantity": 1
        }
      }
    },
    "manualParticles": [],
    "motion": {
      "disable": false,
      "reduce": {
        "factor": 4,
        "value": true
      }
    },
    "particles": {
      "bounce": {
        "horizontal": {
          "random": {
            "enable": false,
            "minimumValue": 0.1
          },
          "value": 1
        },
        "vertical": {
          "random": {
            "enable": false,
            "minimumValue": 0.1
          },
          "value": 1
        }
      },
      "collisions": {
        "bounce": {
          "horizontal": {
            "random": {
              "enable": false,
              "minimumValue": 0.1
            },
            "value": 1
          },
          "vertical": {
            "random": {
              "enable": false,
              "minimumValue": 0.1
            },
            "value": 1
          }
        },
        "enable": true,
        "mode": "bounce",
        "overlap": {
          "enable": false,
          "retries": 0
        }
      },
      "color": {
        "value": "#ffffff",
        "animation": {
          "h": {
            "count": 0,
            "enable": false,
            "offset": 0,
            "speed": 1,
            "sync": true
          },
          "s": {
            "count": 0,
            "enable": false,
            "offset": 0,
            "speed": 1,
            "sync": true
          },
          "l": {
            "count": 0,
            "enable": false,
            "offset": 0,
            "speed": 1,
            "sync": true
          }
        }
      },
      "destroy": {
        "mode": "none",
        "split": {
          "count": 1,
          "factor": {
            "random": {
              "enable": false,
              "minimumValue": 0
            },
            "value": 3
          },
          "rate": {
            "random": {
              "enable": false,
              "minimumValue": 0
            },
            "value": {
              "min": 4,
              "max": 9
            }
          },
          "sizeOffset": true
        }
      },
      "groups": {},
      "life": {
        "count": 0,
        "delay": {
          "random": {
            "enable": false,
            "minimumValue": 0
          },
          "value": 0,
          "sync": false
        },
        "duration": {
          "random": {
            "enable": false,
            "minimumValue": 0.0001
          },
          "value": 0,
          "sync": false
        }
      },
      "links": {
        "blink": false,
        "color": {
          "value": "#ffffff"
        },
        "consent": false,
        "distance": 150,
        "enable": false,
        "frequency": 1,
        "opacity": 0.1,
        "shadow": {
          "blur": 5,
          "color": {
            "value": "#00ff00"
          },
          "enable": false
        },
        "triangles": {
          "enable": false,
          "frequency": 1
        },
        "width": 1,
        "warp": false
      },
      "move": {
        "angle": {
          "offset": 0,
          "value": 90
        },
        "attract": {
          "distance": 200,
          "enable": false,
          "rotate": {
            "x": 600,
            "y": 1200
          }
        },
        "decay": 0,
        "distance": {},
        "direction": "none",
        "drift": 0,
        "enable": true,
        "gravity": {
          "acceleration": 9.81,
          "enable": false,
          "inverse": false,
          "maxSpeed": 50
        },
        "path": {
          "clamp": true,
          "delay": {
            "random": {
              "enable": false,
              "minimumValue": 0
            },
            "value": 0
          },
          "enable": false
        },
        "outModes": {
          "default": "bounce",
          "bottom": "bounce",
          "left": "bounce",
          "right": "bounce",
          "top": "bounce"
        },
        "random": false,
        "size": false,
        "speed": 0.8,
        "spin": {
          "acceleration": 0,
          "enable": false
        },
        "straight": false,
        "trail": {
          "enable": false,
          "length": 10,
          "fillColor": {
            "value": "#000000"
          }
        },
        "vibrate": false,
        "warp": false
      },
      "number": {
        "density": {
          "enable": true,
          "area": 800,
          "factor": 1000
        },
        "limit": 0,
        "value": 4
      },
      "opacity": {
        "random": {
          "enable": true,
          "minimumValue": 0.1
        },
        "value": 0.1,
        "animation": {
          "count": 0,
          "enable": false,
          "speed": 1,
          "sync": false,
          "destroy": "none",
          "minimumValue": 0.1,
          "startValue": "random"
        }
      },
      "orbit": {
        "animation": {
          "count": 0,
          "enable": false,
          "speed": 1,
          "sync": false
        },
        "enable": false,
        "opacity": 1,
        "rotation": {
          "random": {
            "enable": false,
            "minimumValue": 0
          },
          "value": 45
        },
        "width": 1
      },
      "reduceDuplicates": false,
      "repulse": {
        "random": {
          "enable": false,
          "minimumValue": 0
        },
        "value": 0,
        "enabled": false,
        "distance": 1,
        "duration": 1,
        "factor": 1,
        "speed": 1
      },
      "roll": {
        "darken": {
          "enable": false,
          "value": 0
        },
        "enable": false,
        "enlighten": {
          "enable": false,
          "value": 0
        },
        "speed": 25
      },
      "rotate": {
        "random": {
          "enable": false,
          "minimumValue": 0
        },
        "value": 0,
        "animation": {
          "enable": false,
          "speed": 0,
          "sync": false
        },
        "direction": "clockwise",
        "path": false
      },
      "shadow": {
        "blur": 0,
        "color": {
          "value": "#000000"
        },
        "enable": false,
        "offset": {
          "x": 0,
          "y": 0
        }
      },
      "shape": {
        "options": {},
        "type": "circle"
      },
      "size": {
        "random": {
          "enable": true,
          "minimumValue": 100
        },
        "value": {
          "min": 100,
          "max": 200
        },
        "animation": {
          "count": 0,
          "enable": false,
          "speed": 40,
          "sync": false,
          "destroy": "none",
          "minimumValue": 0.1,
          "startValue": "random"
        }
      },
      "stroke": {
        "width": 0
      },
      "tilt": {
        "random": {
          "enable": false,
          "minimumValue": 0
        },
        "value": 0,
        "animation": {
          "enable": false,
          "speed": 0,
          "sync": false
        },
        "direction": "clockwise",
        "enable": false
      },
      "twinkle": {
        "lines": {
          "enable": false,
          "frequency": 0.05,
          "opacity": 1
        },
        "particles": {
          "enable": false,
          "frequency": 0.05,
          "opacity": 1
        }
      },
      "wobble": {
        "distance": 5,
        "enable": false,
        "speed": 50
      },
      "zIndex": {
        "random": {
          "enable": false,
          "minimumValue": 0
        },
        "value": 0,
        "opacityRate": 1,
        "sizeRate": 1,
        "velocityRate": 1
      }
    },
    "pauseOnBlur": true,
    "pauseOnOutsideViewport": true,
    "responsive": [],
    "themes": []
  }

  const tsConf2 =
  {
    "background": {
      "color":
        { "value": dark ? "#080a20" : "#fdfeff" },

      "position": "50% 50%",
      "repeat": "no-repeat",
      "size": "cover"
    },
    "fullScreen": {
      "enable": true,
      "zIndex": -1
    },
    "interactivity": {
      "detectsOn": "window",
      "events": {
        "onDiv": {
          "selectors": "#repulse-div",
          "mode": "repulse"
        },
        "onHover": {
          "enable": true,
          "mode": "connect",
          "parallax": {
            "force": 60
          }
        }
      },
      "modes": {
        "bubble": {
          "distance": 400,
          "duration": 2,
          "opacity": 0.8,
          "size": 40
        },
        "grab": {
          "distance": 400
        }
      }
    },
    "particles": {
      "color": {
        "value": "random",
      },
      "links": {
        "color": {
          "value": "#ffffff"
        },
        "distance": 150,
        "opacity": 0
      },
      "move": {
        "attract": {
          "rotate": {
            "x": 600,
            "y": 1200
          }
        },
        "enable": true,
        "path": {},
        "outModes": {
          "bottom": "out",
          "left": "out",
          "right": "out",
          "top": "out"
        },
        "speed": 0.2,
        "spin": {}
      },
      "number": {
        "density": {
          "enable": true
        },
        "limit": 300,
        "value": 200
      },
      "opacity": {
        "value": 0.3,
        "animation": {
          "speed": 1,
          "minimumValue": 0.1
        }
      },
      "size": {
        "random": {
          "enable": true
        },
        "value": {
          "min": 1,
          "max": 8
        },
        "animation": {
          "speed": 40,
          "minimumValue": 0.1
        }
      }
    }
  }

  function getPage(p) {
    switch (p) {
      case '':
        return <Start dark={dark} setPage={setPage} />
      case '/#/ueber':
        return <Ueber setPage={setPage} dark={dark} />
      case '/#/leistungen':
        return <Leistungen setPage={setPage} dark={dark} />
      case '/#/projekte':
        return <Projekte setPage={setPage} />
      case '/#/jobs':
        return <Jobs setPage={setPage} />
      case '/#/referenzen':
        return <Referenzen setPage={setPage} dark={dark} />
      case '/#/kontakt':
        return <Kontakt setPage={setPage} dark={dark} />
      case '/#/impressum':
        return <Impressum setPage={setPage} />
      default:
        throw new Error('Unknown page');
    }
  }

  const useMove = () => {
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 })

    const handleMouseMove = e => {
      e.persist()
      setMousePos(mousePos => ({ ...mousePos, x: e.clientX, y: e.clientY }))
    }
    return {
      x: mousePos.x,
      y: mousePos.y,
      handleMouseMove,
    }
  }

  return (
    <div className={"App"} style={dark ? { color: "rgb(213, 214, 233)" } : { color: "rgb(8, 10, 32)" }} >

      <Router>
        <Header dark={dark} setDark={setDark} page={page} setPage={setPage} />
        <Container height="100%" maxWidth="lg">
          <div className={classes.root}>
            {/* <TransitionGroup>
            
              <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={300}
              >
                <Switch location={location}>
                  <Route path="/ueber" children={<p>ueber</p>} />
                  <Route path="/leistungen" children={<p>leistungen</p>} />
                  <Route path="/projekte" children={<p>projekte</p>} />
                  <Route path="/team" children={<p>team</p>} />
                  <Route path="/referenzen" children={<p>referenzen</p>} />
                </Switch>
              </CSSTransition>
            </TransitionGroup> */}
            {getPage(page)}
          </div>
        </Container>
      </Router>

      {
        tsConfImage
          ?
          <div
            style={{
              backgroundColor: dark ? "#080a20" : "#fdfeff",
              position: 'absolute',
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
              zIndex: "-1",
            }}
          >
            <img
              src={map}
              style={{
                filter: dark ? "invert(100%)" : "invert(0%)",
                height: "100%",
                width: "100%",
                objectFit: "cover",
                zIndex: "-1",
                opacity: 0.5
              }}
            />
          </div>
          :
          <Particles
            id="tsparticles"
            options={tsConf2}
          />}
    </div>
  );
}

export default App;
