import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Slide from '@material-ui/core/Slide';

export default function Start(props) {

  const [slideIn1, setSlideIn1] = React.useState(false);
  const [slideIn2, setSlideIn2] = React.useState(false);

  useEffect(() => {
    setSlideIn1(true)
    setTimeout(() => {
      setSlideIn2(true)
    }, 200);
  }, [])

  return (

    <Grid
      style={{ height: '80vh' }}
      container
      direction="row"
      justifyContent="center"
      alignContent="center"
      spacing={3}
    >
      <Slide direction="right" in={slideIn1} {...(slideIn1 ? { timeout: 800 } : {})} mountOnEnter unmountOnExit>

        <Grid item xs={12} onClick={() => {
          props.setPage('/#/ueber')
        }}
        >
          <Typography style={{ fontSize: "calc(20px + 4vw)" }}>
            ECKNOLOGY GMBH
          </Typography>
        </Grid>
      </Slide >
      <Slide direction="left" in={slideIn1} {...(slideIn1 ? { timeout: 1000 } : {})} mountOnEnter unmountOnExit>
        <Grid item xs={4} onClick={() => {
          props.setPage('/#/ueber')
        }}   >
          <Typography align="left" style={{ fontSize: "calc(12px + 2vw)" }} >
            ALL<br></br>
            ABOUT<br></br>
            <span style={props.dark ? { color: '#3abb41', filter: "invert(100%)" } : { color: '#3abb41', filter: "invert(0%)" }}>
              TECHNOLOGY
            </span>
          </Typography>
          {/* <div className="mouseInfo">
  The current mouse position is ({x}, {y})
</div> */}
        </Grid >

      </Slide >
    </Grid >

  );
}


